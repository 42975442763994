.rootInputSelect {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.rootInputSelect .formControl {
  width: 320px;
  margin-right: 24px;
}

.btnClear {
    height: 50px;
    width: 190px;
    margin-left: 16px;
}