.rootLoginPage {
  display: flex;
  justify-content: center;
}

.formLogin {
  display: block;
  padding: 5% 5% 5%;
  margin-top: 40px;
  height: 800px;
  width: 800px;
  border: solid 2px slategray;
}