.labelEditor {
  margin-left: 16px;
}

.formRichText {
  border: rgba(0, 0, 0, 0.281);
  border-style: groove;
  border-width: thin;
  height: 260px;
  margin: 16px 0px 16px;
  padding: 6px 0px 6px;
}

.divChoice {
  padding: 16px 0 26px 16px;
}