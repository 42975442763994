.rootForm {
  border-radius: 1em;
  background-color: white;
  padding: 28px 28px 28px 28px;
}
.headerForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.bodyForm {
  display: flex;
  flex-direction: column;
}

.rishTextForm {
  width: 260px;
  margin-bottom: 20px;
}

.footerForm {
  display: flex;
  justify-content: end;
  border-radius: 1em;
  background-color: white;
  padding: 28px 28px 28px 28px;
}


.footerForm .BtnCancel {
  margin-right: 10px;
}

.footerForm .BtnSave {
  margin-left: 16px;
}
