.rootForm {
    overflow: hidden;
}
.headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
.layoutForm {
    display: flex;
    flex-direction: column;

}

.inputForm {
    width: 400px;
    margin-bottom: 20px;
    background-color: white;
}

.formRichText {
    border: black;
    background-color: white;
    height: 260px;
    margin-bottom: 24px;
    padding: 16px 16px 16px;
}

.divBtnConfirm {
    margin-top: 18px;
    margin-left: auto;
}

.btn {
    margin-left: 16px;
    margin-right: 16px;
}
